import BasicCard, { BasicCardButtonDef } from 'components/card/basic-card';
import React from 'react';
import { Button, TextField } from 'components/form';
import { RepairDef, RepairItemDef } from 'components/api/endpoints/repairs';
import useApi from 'components/api/use-api';
import 'styles/components/_repair-items.scss';
import useList, { getPayloads } from 'components/form/use-list';
import { useSnackbar } from 'notistack';

export type RepairItemsCardProps = {
    repair: RepairDef;
};

const newItem: RepairItemDef = {
    price: 0,
    name: '',
    quantity: 1,
};

const RepairItemsCard = ({ repair }: RepairItemsCardProps) => {
    const api = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { items, add } = useList(repair.items);

    const saveBtn: BasicCardButtonDef = {
        label: 'Uložit',
        action: () =>
            api.repairs
                .updateItems(repair.id, getPayloads(items))
                .then(() =>
                    enqueueSnackbar('Položky byly uloženy.', {
                        variant: 'success',
                    })
                )
                .catch(() =>
                    enqueueSnackbar('Položky se nepodařilo uložit', {
                        variant: 'error',
                    })
                ),
    };

    return (
        <BasicCard header="Položky opravy" buttons={saveBtn}>
            <table className="c-repair-items">
                <thead>
                    <tr>
                        <th>Název</th>
                        <th>Množství</th>
                        <th>Cena</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <tr key={item.key}>
                            <td className="c-repair-items__name-field">
                                <TextField
                                    value={item.payload.name}
                                    onChange={(name) =>
                                        item.update({ ...item.payload, name })
                                    }
                                />
                            </td>
                            <td className="c-repair-items__quantity-field">
                                <TextField
                                    type="number"
                                    value={item.payload.quantity.toString()}
                                    onChange={(quantity) =>
                                        item.update({
                                            ...item.payload,
                                            quantity: Number(quantity),
                                        })
                                    }
                                />
                            </td>
                            <td className="c-repair-items__price-field">
                                <TextField
                                    type="number"
                                    value={item.payload.price.toString()}
                                    onChange={(price) =>
                                        item.update({
                                            ...item.payload,
                                            price: Number(price),
                                        })
                                    }
                                />
                            </td>
                            <td className="c-repair-items__controls-field">
                                <Button
                                    btnType="grey"
                                    onClick={() => item.remove()}
                                >
                                    x
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <Button style={{ margin: 'auto' }} onClick={() => add(newItem)}>
                Přidat
            </Button>
        </BasicCard>
    );
};

export default RepairItemsCard;
