import axios from 'axios';
import * as process from 'process';

export type RequestDef = {
    route: string;
    key?: string;
    data?: any;
    params?: any;
};

export type SearchResultDef<T> = {
    items: T[];
};

export const apiUrl =
    process?.env?.REACT_APP_API_URL ||
    'https://api.automatickeprevodovkypraha.cz';

export const route = (route: string) => `${apiUrl}/api/1/${route}`;

export const get = async <T = void>(request: RequestDef): Promise<T> =>
    send('get', request);

export const post = async <T = void>(request: RequestDef): Promise<T> =>
    send('post', request);

export const put = async <T = void>(request: RequestDef): Promise<T> =>
    send('put', request);

export const httpDelete = async <T = void>(request: RequestDef): Promise<T> =>
    send('delete', request);

const send = async <T>(
    method: 'get' | 'post' | 'put' | 'delete',
    request: RequestDef
): Promise<T> => {
    const response = await axios.request<T>({
        url: route(request.route),
        method,
        data: request.data,
        params: request.params,
        paramsSerializer: { indexes: true },
        headers: {
            Authorization: request.key ? `Bearer ${request.key}` : null,
        },
    });

    return response.data;
};
