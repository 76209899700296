import { createContext, useMemo, useState } from 'react';
import useApiEndpoints, {
    ApiEndpoints,
    createEndpoints,
} from 'components/api/use-api-endpoints';

export type ProvideApiProps = {
    children?: React.ReactNode;
};

export type ApiContextValueDef = {
    endpoints: ApiEndpoints;
    setKey: (key?: string) => void;
    key?: string;
};

export const ApiContext = createContext<ApiContextValueDef>({
    endpoints: createEndpoints(),
    setKey: () => void 1,
});

export const ApiContextProvider = ({ children }: ProvideApiProps) => {
    const [key, setKey] = useState<string | undefined>();
    const api = useApiEndpoints({ key });

    const value = useMemo<ApiContextValueDef>(
        () => ({
            endpoints: createEndpoints(key),
            setKey,
            key,
        }),
        [key]
    );

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};
