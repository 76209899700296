import React, { useState } from 'react';
import { Calendar3 } from 'react-bootstrap-icons';
import Modal from 'components/modal/modal';
import TimeWindowsCalendar from 'components/time-windows/time-windows-calendar';

const TimeWindowSelectIcon = () => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Calendar3 onClick={() => setOpen(true)} />
            <Modal open={open} onClose={() => setOpen(false)}>
                <TimeWindowsCalendar />
            </Modal>
        </>
    );
};

export default TimeWindowSelectIcon;
