import { useEffect, useState } from 'react';
import useLocalStorage from 'components/local-storage/use-local-storage';

export type DisplayTypeDef = typeof displayTypes[number];

const displayTypes = ['calendar', 'table'] as const;

const displayParamName = 'display';

export default (): [DisplayTypeDef, (type: DisplayTypeDef) => void] => {
    const [persistedBookingDisplay, setPersistedBookingDisplay] =
        useLocalStorage<DisplayTypeDef | null>('booking-display-type');
    const [display, setDisplay] = useState<DisplayTypeDef>(() => {
        return displayTypes.includes(persistedBookingDisplay as DisplayTypeDef)
            ? (persistedBookingDisplay as DisplayTypeDef)
            : 'calendar';
    });

    useEffect(() => {
        setPersistedBookingDisplay(display);
    }, [display]);

    return [display, setDisplay];
};
