import React, { useEffect } from 'react';

import 'styles/components/_button.scss';
import { className } from 'utils/components';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
    children?: React.ReactNode;
    onClick?: () => void;
    isLoading?: boolean;
    shake?: boolean;
    btnType?: 'grey' | 'hollow';
};

const Button = ({
    children,
    isLoading,
    onClick,
    shake,
    btnType,
    className: additionalClassName,
    ...additionalProps
}: ButtonProps) => {
    useEffect(() => {}, [shake]);

    return (
        <button
            {...additionalProps}
            className={className('c-button', additionalClassName, {
                'c-button--shake': shake,
                ['c-button--' + btnType]: btnType,
            })}
            onClick={onClick}
        >
            {isLoading ? <Spinner /> : children}
        </button>
    );
};

const Spinner = () => {
    return (
        <svg className="c-button__spinner" viewBox="0 0 50 50">
            <circle
                className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                stroke="currentColor"
                strokeWidth="5"
            ></circle>
        </svg>
    );
};

export default Button;
