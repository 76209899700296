import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './styles/generic/_normalize.css';
import './styles/elements/_anchor.scss';
import './styles/elements/_body.scss';
import { ApiContextProvider } from 'components/api/api-context';
import { AuthContextProvider } from 'components/auth/auth-context';
import { App } from 'components';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ApiContextProvider>
                <AuthContextProvider>
                    <App />
                </AuthContextProvider>
            </ApiContextProvider>
        </BrowserRouter>
    </React.StrictMode>
);
