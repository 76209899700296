import React from 'react';
import 'styles/components/_highlight-area.scss';

export type HighlightAreaProps = {
    children?: React.ReactNode;
};

const HighlightArea = ({ children }: HighlightAreaProps) => {
    return <div className="c-highlight-area">{children}</div>;
};

export default HighlightArea;
