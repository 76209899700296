import React from 'react';
import { className } from 'utils/components';

export type DataListItemProps = {
    label: string;
    children?: React.ReactNode;
    italic?: boolean;
};

const DataListItem = ({ label, children, italic }: DataListItemProps) => {
    return (
        <li className="c-data-list__item">
            <span className="c-data-list__label">{label}</span>
            <div
                className={className('c-data-list__value', {
                    'c-data-list__value--italic': !!italic,
                })}
            >
                {children}
            </div>
        </li>
    );
};

export default DataListItem;
