import React from 'react';
import BookingCalendar from 'components/bookings/booking-calendar';
import { Button } from 'components/form';
import { Calendar2, Table } from 'react-bootstrap-icons';
import BookingsTable from 'components/bookings/bookings-table';
import { Card } from 'components/card';
import CardHeader from 'components/card/card-header';
import CardContent from 'components/card/card-content';
import useDisplayType from 'components/bookings/use-display-type';

type DisplayTypeDef = typeof displayTypes[number];

const displayTypes = ['calendar', 'table'] as const;

const displayParamName = 'display';

const BookingsDashboardPage = () => {
    const [display, setDisplay] = useDisplayType();

    return (
        <div>
            <Card>
                <CardHeader>
                    <span>Rezervace</span>

                    <div>
                        <Button
                            onClick={() => setDisplay('calendar')}
                            btnType={display === 'calendar' ? 'grey' : 'hollow'}
                        >
                            <Calendar2 />
                        </Button>
                        <Button
                            onClick={() => setDisplay('table')}
                            btnType={display === 'table' ? 'grey' : 'hollow'}
                        >
                            <Table />
                        </Button>
                    </div>
                </CardHeader>

                <CardContent>
                    {display === 'calendar' ? <BookingCalendar /> : null}
                    {display === 'table' ? <BookingsTable /> : null}
                </CardContent>
            </Card>
        </div>
    );
};

export default BookingsDashboardPage;
