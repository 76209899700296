import React from 'react';
import BasicCard from 'components/card/basic-card';
import TimeWindowsCalendar from 'components/time-windows/time-windows-calendar';

const TimeWindowsDashboardPage = () => {
    return (
        <div>
            <BasicCard>
                <TimeWindowsCalendar />
            </BasicCard>
        </div>
    );
};

export default TimeWindowsDashboardPage;
