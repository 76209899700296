import React from 'react';
import DataTable, { ColumnDef } from 'components/table/data-table';
import useSearch from 'components/api/use-search';
import { RepairDef } from 'components/api/endpoints/repairs';
import { Link } from 'react-router-dom';
import { PencilSquare } from 'react-bootstrap-icons';

const columns: ColumnDef<RepairDef>[] = [
    {
        header: 'Zákazník',
        accessor: 'contact.name',
    },
    {
        header: 'Výrobce',
        accessor: (r) => `${r.vehicle.manufacturer}`,
    },
    {
        header: 'Model',
        accessor: (r) => `${r.vehicle.model}`,
    },
    {
        header: 'Typ',
        accessor: (r) => `${r.vehicle.type}`,
    },
    {
        header: 'Závady',
        accessor: (r) => r.defects.length,
        width: 'shrink',
        align: 'center',
    },
    {
        width: 'shrink',
        accessor: (b) => (
            <Link to={`/repairs/${b.id}`}>
                <PencilSquare />
            </Link>
        ),
    },
];

const RepairsTable = () => {
    const { items } = useSearch((e) => e.repairs.search());

    return <DataTable columns={columns} data={items} />;
};

export default RepairsTable;
