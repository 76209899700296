import React, { useState } from 'react';
import { Card } from 'components/card';
import 'styles/components/_login.scss';
import { Button, TextField } from 'components/form';
import useAuth from 'components/auth/use-auth';

type UserCredentials = {
    username: string;
    password: string;
};

const Login = () => {
    const auth = useAuth();
    const [userCredentials, setUserCredentials] = useState<UserCredentials>({
        username: '',
        password: '',
    });
    const [loggingState, setLoggingState] = useState<
        'none' | 'loading' | 'error'
    >('none');

    const login = () => {
        auth.login(userCredentials.username, userCredentials.password)
            .then(() => setLoggingState('none'))
            .catch(() => setLoggingState('error'));
    };

    return (
        <form
            className="c-login"
            onSubmit={(e) => {
                e.preventDefault();
                login();
            }}
        >
            <Card className={'c-card--login'}>
                <h1 className="c-login__title">Přihlášení</h1>
                <TextField
                    label="Přihlašovací jméno"
                    onChange={(username) =>
                        setUserCredentials({ ...userCredentials, username })
                    }
                    value={userCredentials.username}
                />
                <TextField
                    label="Heslo"
                    onChange={(password) =>
                        setUserCredentials({ ...userCredentials, password })
                    }
                    value={userCredentials.password}
                    type="password"
                />
                <Button
                    isLoading={loggingState === 'loading'}
                    shake={loggingState === 'error'}
                >
                    Přihlásit se
                </Button>
            </Card>
        </form>
    );
};

export default Login;
