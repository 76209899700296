import React from 'react';
import { Link, useParams } from 'react-router-dom';
import BasicCard from 'components/card/basic-card';
import useResource from 'components/api/use-resource';
import useApi from 'components/api/use-api';
import { formatDaytime } from 'utils/time';
import DataList from 'components/data-list/data-list';
import DataListItem from 'components/data-list/data-list-item';
import Switch from 'components/form/switch';

const TimeWindowEditPage = () => {
    const api = useApi();
    const { timeWindowId } = useParams();
    const { resource: timeWindow } = useResource(() =>
        api.schedules.getTimeWindow(timeWindowId as string)
    );

    if (!timeWindow) {
        return null;
    }

    const dateFrom = new Date(timeWindow.from);
    const dateTo = new Date(timeWindow.to);

    return (
        <BasicCard
            header={`Časové okno ${dateFrom.toLocaleDateString()} ${formatDaytime(
                dateFrom
            )}-${formatDaytime(dateTo)}`}
        >
            <DataList>
                <DataListItem label="Rozvrh">
                    <Link to={`/schedules/${timeWindow.scheduleId}`}>
                        {timeWindow.scheduleName}
                    </Link>
                </DataListItem>
                <DataListItem label="Sloty">{timeWindow.slots}</DataListItem>
                <DataListItem label="Volné sloty">
                    {timeWindow.freeSlots}
                </DataListItem>
                <DataListItem label="Datum">
                    {dateFrom.toLocaleDateString()}
                </DataListItem>
                <DataListItem label="Čas">
                    {`${formatDaytime(dateFrom)}-${formatDaytime(dateTo)}`}
                </DataListItem>
                <DataListItem label="Povoleno">
                    <Switch />
                </DataListItem>
            </DataList>
        </BasicCard>
    );
};

export default TimeWindowEditPage;
