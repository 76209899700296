import React, { useState } from 'react';
import { RepairDef } from 'components/api/endpoints/repairs';
import BasicCard, { BasicCardButtonDef } from 'components/card/basic-card';
import { TextField } from 'components/form';
import useApi from 'components/api/use-api';
import { useHandlePromise } from 'utils/snackbar/notistack';

export type RepairSolutionCardProps = {
    repair: RepairDef;
};

const RepairSolutionCard = ({ repair }: RepairSolutionCardProps) => {
    const api = useApi();
    const handlePromise = useHandlePromise();
    const [solution, setSolution] = useState(repair.solution);

    const saveBtn: BasicCardButtonDef = {
        action: () =>
            handlePromise(
                api.repairs.updateSolution(repair.id, solution || ''),
                'Řešení bylo uloženo.',
                'Řešení se nepodařilo uložit.'
            ),
        label: 'Uložit',
        // disabled: solution === repair.solution,
    };

    return (
        <BasicCard header="Popis řešení" buttons={saveBtn}>
            <TextField
                type="textarea"
                value={solution}
                onChange={setSolution}
                description="Popis řešení opravy vozidla. Tato informace je zaslána zákazníkovi pomocí e-mailu."
            />
        </BasicCard>
    );
};

export default RepairSolutionCard;
