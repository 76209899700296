import React from 'react';
import 'styles/components/_radio-button.scss';
import 'styles/components/_list.scss';
import RadioButton from 'components/form/radio-button';

export interface RadioButtonListItemProps {
    value: string;
    label: string;
    description?: string;
}

const RadioButtonListItem = (props: RadioButtonListItemProps) => {
    return (
        <li className="c-list__item">
            <RadioButton label={props.label} description={props.description} />
        </li>
    );
};

export default RadioButtonListItem;
