import React from 'react';
import Menu from './menu';
import 'styles/components/_admin-layout.scss';
import 'styles/components/_logo.scss';
import { ReactComponent as Logo } from 'images/logo.svg';
import Topbar, { BreadcrumbItem } from './topbar';
import { Link } from 'react-router-dom';

export interface AdminProps {
    title: string;
    component: React.ReactNode;
    breadcrumb?: BreadcrumbItem[];
}

const Admin = (props: AdminProps) => {
    return (
        <div className="c-admin-layout">
            <div className="c-admin-layout__logo">
                <Link to={'/'}>
                    <Logo className="c-logo" />
                </Link>
            </div>
            <div className="c-admin-layout__topbar">
                <Topbar title={props.title} />
            </div>
            <div className="c-admin-layout__menu">
                <Menu />
            </div>
            <main className="c-admin-layout__main-content">
                {props.component}
            </main>
        </div>
    );
};

export default Admin;
