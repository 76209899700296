import React from 'react';
import DataTable, { ColumnDef } from 'components/table/data-table';
import { BookingDef, SearchFilterDef } from 'components/api/endpoints/bookings';
import { formatDaytime } from 'utils/time';
import { Link } from 'react-router-dom';
import { Check, PencilSquare, X } from 'react-bootstrap-icons';
import useSearch from 'components/api/use-search';
import BookingConfirmationTag from 'components/bookings/booking-confirmation-tag';

export type BookingsTableProps = {
    filter?: SearchFilterDef;
};

const columns: ColumnDef<BookingDef>[] = [
    {
        header: 'Zákazník',
        accessor: 'contact.name',
    },
    {
        header: 'Výrobce',
        accessor: (b) => `${b.vehicle.manufacturer}`,
    },
    {
        header: 'Model',
        accessor: (b) => `${b.vehicle.model}`,
    },
    {
        header: 'Typ',
        accessor: (b) => `${b.vehicle.type}`,
    },
    {
        header: 'Stav',
        accessor: (b) => <BookingConfirmationTag booking={b} />,
        align: 'center',
        width: 'shrink',
    },
    {
        header: 'Opravenka',
        width: 'shrink',
        accessor: (b) => (b.repairId ? <Check /> : <X />),
        align: 'center',
    },
    {
        header: 'Datum',
        width: 'shrink',
        accessor: (b) => new Date(b.from).toLocaleDateString(),
    },
    {
        header: 'Čas',
        width: 'shrink',
        accessor: (b) =>
            `${formatDaytime(new Date(b.from))} - ${formatDaytime(
                new Date(b.to)
            )}`,
    },
    {
        header: 'Vytvořeno',
        width: 'shrink',
        accessor: (b) => new Date(b.createdAt).toLocaleDateString(),
    },
    {
        width: 'shrink',
        accessor: (b) => (
            <Link to={`/bookings/${b.id}`}>
                <PencilSquare />
            </Link>
        ),
    },
];

const BookingsTable = ({ filter }: BookingsTableProps) => {
    const { items: bookings } = useSearch((api) => api.bookings.search(filter));

    return <DataTable columns={columns} data={bookings} />;
};

export default BookingsTable;
