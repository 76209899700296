import { useState } from 'react';
import { changeAt, removeAt } from 'utils/array';

export type UseListResultDef<T> = {
    items: ListItemDef<T>[];
    add: (item: T) => void;
};

export type ListItemDef<T> = ListDataItemDef<T> & {
    remove: () => void;
    update: (item: T) => void;
};

export type ListDataItemDef<T> = {
    key: string;
    payload: T;
};

const createDataItem = <T>(payload: T): ListDataItemDef<T> => ({
    key: crypto.randomUUID(),
    payload,
});

export const getPayloads = <T>(items: ListDataItemDef<T>[]) =>
    items.map((i) => i.payload);

export default <T>(defaultItems: T[]): UseListResultDef<T> => {
    const [items, setItems] = useState<ListDataItemDef<T>[]>(() =>
        defaultItems.map(createDataItem)
    );

    return {
        items: items.map((item, index) => ({
            ...item,
            remove: () => setItems(removeAt(items, index)),
            update: (payload) =>
                setItems(changeAt(items, index, { ...item, payload })),
        })),
        add: (item: T) => setItems([...items, createDataItem(item)]),
    };
};
