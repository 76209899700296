import React from 'react';
import 'styles/components/_card.scss';
import CardHeader from 'components/card/card-header';
import Card from 'components/card/card';
import CardFooter from 'components/card/card-footer';
import { Button } from 'components/form';
import CardContent from 'components/card/card-content';
import { Optional } from 'utils/types';

export type BasicCardButtonDef = {
    label: string;
    action: () => void;
    disabled?: boolean;
};

export type BasicCardProps = React.HTMLAttributes<HTMLDivElement> & {
    header?: Optional<React.ReactElement | string>;
    children?: React.ReactNode;
    buttons?: Optional<BasicCardButtonDef | BasicCardButtonDef[]>;
    toolbarButtons?: BasicCardButtonDef | BasicCardButtonDef[];
    bodyProps?: React.HTMLAttributes<HTMLDivElement>;
    loading?: boolean;
    skeleton?: boolean;
};

const inputButtonToArray = (
    buttons: Optional<BasicCardButtonDef | BasicCardButtonDef[]>
): undefined | BasicCardButtonDef[] => {
    if (!buttons) {
        return undefined;
    }

    return !Array.isArray(buttons) ? [buttons] : buttons;
};

const BasicCard = ({
    children,
    loading,
    buttons,
    toolbarButtons,
    header,
    skeleton,
    bodyProps: originalBodyProps,
    ...defaultProps
}: BasicCardProps) => {
    const bodyProps = loading
        ? {
              ...originalBodyProps,
              className: `${
                  originalBodyProps?.className || ''
              } c-card__body--loading`,
          }
        : originalBodyProps;

    const buttonsArr = inputButtonToArray(buttons);
    const toolbarButtonsArr = inputButtonToArray(toolbarButtons);
    const hasToolbarButtons = !!toolbarButtonsArr?.length;

    return (
        <Card {...defaultProps}>
            {header || hasToolbarButtons ? (
                <CardHeader>
                    {header}

                    {hasToolbarButtons ? (
                        <div className="c-card__toolbar">
                            {toolbarButtonsArr.map((button, i) => (
                                <Button onClick={button.action} key={i}>
                                    {button.label}
                                </Button>
                            ))}
                        </div>
                    ) : null}
                </CardHeader>
            ) : null}
            <CardContent {...bodyProps}>{children}</CardContent>
            {buttonsArr && buttonsArr.length > 0 ? (
                <CardFooter>
                    {buttonsArr.map((button, i) => (
                        <Button
                            onClick={button.action}
                            key={i}
                            disabled={button.disabled}
                        >
                            {button.label}
                        </Button>
                    ))}
                </CardFooter>
            ) : null}
        </Card>
    );
};

export default BasicCard;
