import React from 'react';
import 'styles/components/_switch.scss';

export type SwitchProps = React.HTMLAttributes<HTMLInputElement> & {
    checked?: boolean;
};

const Switch = ({ checked, ...additionalProps }: SwitchProps) => {
    return (
        <input
            {...additionalProps}
            checked={checked}
            className="c-switch"
            type="checkbox"
        />
    );
};

export default Switch;
