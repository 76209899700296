import { createContext } from 'react';
import useProvideAuth, {
    ProvideAuthResultDef,
} from 'components/auth/use-provide-auth';

export type AuthContextProviderProps = {
    children?: React.ReactNode;
};

export const AuthContext = createContext<ProvideAuthResultDef>({
    logout: () => void 1,
    login: () => new Promise((resolve) => resolve(false)),
    user: null,
});

export const AuthContextProvider = ({ children }: AuthContextProviderProps) => {
    const value = useProvideAuth();

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};
