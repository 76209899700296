import React, { useState } from 'react';
import BasicCard from 'components/card/basic-card';
import { BookingDef } from 'components/api/endpoints/bookings';
import { Button } from 'components/form';
import useApi from 'components/api/use-api';
import { Link } from 'react-router-dom';

export type BookingRepairCardProps = {
    booking: BookingDef;
};

const BookingRepairCard = ({ booking }: BookingRepairCardProps) => {
    const api = useApi();
    const [repairId, setRepairId] = useState(booking.repairId);

    const createRepair = () =>
        api.bookings
            .createRepair(booking.id)
            .then((r) => setRepairId(r.repairId));

    return (
        <BasicCard header="Opravenka">
            {repairId ? (
                <Link
                    to={`/repairs/${repairId}`}
                    className="c-button c-button--grey"
                >
                    zobrazit
                </Link>
            ) : (
                <Button onClick={() => api.bookings.createRepair(booking.id)}>
                    vytvořit
                </Button>
            )}
        </BasicCard>
    );
};

export default BookingRepairCard;
