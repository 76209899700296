import { useState } from 'react';

export type LocalStorageValueDef<T> = T | null | ((value: T | null) => void);

// Credit to https://usehooks.com/useLocalStorage/
export default <T>(
    key: string,
    initialValue?: T
): [T | null, (value: LocalStorageValueDef<T>) => void] => {
    const [storedValue, setStoredValue] = useState(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }

        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    const setValue = (value: LocalStorageValueDef<T>) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (typeof window !== 'undefined') {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
};
