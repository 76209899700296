import React from 'react';
import { useParams } from 'react-router-dom';
import useResource from 'components/api/use-resource';
import BasicCard from 'components/card/basic-card';
import RepairVinRecordCard from 'components/repairs/repair-vin-record-card';
import { TextField } from 'components/form';
import RepairItemsCard from 'components/repairs/repair-items-card';
import RepairDefectsCard from 'components/repairs/repair-defects-card';
import RepairSolutionCard from 'components/repairs/repair-solution-card';
import GroupedContent from 'components/content/grouped-content';
import GroupedContentDetail from 'components/content/grouped-content-detail';
import GroupedContentItem from 'components/content/grouped-content-item';

const RepairEditPage = () => {
    const { repairId } = useParams();
    const { resource: repair } = useResource((api) =>
        api.repairs.get(repairId as string)
    );

    if (!repair) {
        return null;
    }

    return (
        <div style={{ maxWidth: '80rem', margin: 'auto' }}>
            <GroupedContent>
                <GroupedContentDetail>
                    <div style={{ display: 'flex', gap: '2rem' }}>
                        <img
                            src="https://mediaservices.arval.com/Large_1.5_x1/Pix_PNG2048/CZ/Dacia/Duster/2022/5SUV/22dusterprestigesu2fb/dacia_22dusterprestigesu2fb_lowaggressive.png"
                            alt=""
                            style={{
                                width: '160px',
                                height: '160px',
                                objectFit: 'contain',
                            }}
                        />

                        <div>
                            <h2 style={{ margin: 0 }}>Vozidlo</h2>
                            <ul style={{ padding: 0, listStyle: 'none' }}>
                                <li>{repair.vehicle.manufacturer}</li>
                                <li>{repair.vehicle.model}</li>
                                <li>{repair.vehicle.type}</li>
                            </ul>
                        </div>

                        <div>
                            <h2 style={{ margin: 0 }}>Kontakt</h2>
                            <ul style={{ padding: 0, listStyle: 'none' }}>
                                <li>{repair.contact.name}</li>
                                <li>{repair.contact.email}</li>
                                <li>{repair.contact.phoneNumber}</li>
                            </ul>
                        </div>
                    </div>
                </GroupedContentDetail>

                <GroupedContentItem name="Závady">
                    <RepairDefectsCard repair={repair} />
                    <BasicCard header="Poznámka">
                        <TextField
                            type="textarea"
                            description="Vyplněné informace slouží pro interní účely a nejsou dostupné zákazníkovi."
                        />
                    </BasicCard>
                </GroupedContentItem>

                <GroupedContentItem name="Opravy">
                    <RepairItemsCard repair={repair} />
                    <RepairSolutionCard repair={repair} />
                </GroupedContentItem>

                <GroupedContentItem name="VIN">
                    <RepairVinRecordCard repair={repair} />
                </GroupedContentItem>
            </GroupedContent>
        </div>
    );
};

export default RepairEditPage;
