import React from 'react';
import 'styles/components/_breadcrumb.scss';
import 'styles/components/_topbar.scss';
import { Button } from 'components/form';
import { Link } from 'react-router-dom';
import useAuth from 'components/auth/use-auth';

export interface BreadcrumbItem {
    name: string;
    link: string;
}

export interface TopbarProps {
    title: string;
    breadcrumb?: BreadcrumbItem[];
}

const Topbar = (props: TopbarProps) => {
    const auth = useAuth();

    return (
        <div className="c-topbar">
            <div className="c-topbar__toolbar">
                <Button onClick={auth.logout}>Odhlásit</Button>
            </div>
            <div className="c-topbar__navbar">
                <h1 className="c-topbar__page-name">{props.title}</h1>
                <div className="c-breadcrumb">
                    <Link to="/" className="c-breadcrumb__item">
                        Domů
                    </Link>
                    <span className="c-breadcrumb__connector">&gt;</span>
                    <span className="c-breadcrumb__item c-breadcrumb__item--current">
                        {props.title}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Topbar;
