import React, { useState } from 'react';
import Modal from 'components/modal/modal';
import { Button, TextField } from 'components/form';
import { useNavigate } from 'react-router-dom';
import useApi from 'components/api/use-api';

const CreateScheduleButton = () => {
    const api = useApi();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [name, setName] = useState('');

    const addSchedule = () => {
        api.schedules
            .add({
                name,
                timeWindows: [],
                czechHolidays: [],
            })
            .then((res) => navigate(`/schedules/${res.id}`))
            .catch(() => setIsOpen(false));
    };

    return (
        <>
            <Button onClick={() => setIsOpen(!isOpen)}>Přidat</Button>

            <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                <TextField label="Název" onChange={setName} value={name} />

                <Button onClick={addSchedule}>Vytvořit</Button>
            </Modal>
        </>
    );
};

export default CreateScheduleButton;
