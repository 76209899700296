import React from 'react';
import 'styles/components/_modal.scss';
import { Dialog } from '@headlessui/react';

export type ModalProps = {
    open: boolean;
    onClose: () => void;
    children?: React.ReactNode;
};

const Modal = ({ open, onClose, children }: ModalProps) => {
    return (
        <Dialog open={open} onClose={onClose} className="c-modal">
            <div className="c-modal__background" aria-hidden="true" />

            <div className="c-modal__windows-container">
                <Dialog.Panel className="c-modal__window">
                    <h2>Muj modal</h2>

                    {children}
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};

export default Modal;
