import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import useSearch from 'components/api/use-search';
import { EventInput } from '@fullcalendar/core';
import { BookingDef } from 'components/api/endpoints/bookings';
import { useNavigate } from 'react-router-dom'; // a plugin!

const getColor = (booking: BookingDef) => {
    const from = new Date(booking.from);
    const to = new Date(booking.to);
    const now = new Date();

    if (to.getTime() < now.getTime()) {
        return 'grey';
    } else if (from.getTime() < now.getTime()) {
        return 'yellow';
    }

    return undefined;
};

const BookingCalendar = () => {
    const { items: bookings } = useSearch((api) => api.bookings.search());
    const navigate = useNavigate();

    const events = bookings?.map<EventInput>((b) => ({
        id: b.id,
        start: new Date(b.from),
        end: new Date(b.to),
        title: b.contact.name,
        color: getColor(b),
    }));

    return (
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            height="60rem"
            headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'today dayGridMonth,timeGridWeek,timeGridDay',
            }}
            buttonText={{
                day: 'den',
                month: 'měsíc',
                week: 'týden',
                today: 'dnes',
            }}
            allDaySlot={false}
            events={events}
            locale="cs-CZ"
            eventClick={(e) => navigate(e.event.id)}
        />
    );
};

export default BookingCalendar;
