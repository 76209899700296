import React from 'react';
import { useParams } from 'react-router-dom';
import useResource from 'components/api/use-resource';
import useApi from 'components/api/use-api';
import BasicCard, { BasicCardButtonDef } from 'components/card/basic-card';
import DataList from 'components/data-list/data-list';
import DataListItem from 'components/data-list/data-list-item';
import BookingTimeWindowField from 'components/bookings/booking-time-window-field';
import { unconfirmed } from 'components/api/endpoints/bookings';
import BookingConfirmationTag from 'components/bookings/booking-confirmation-tag';
import BookingRepairCard from 'components/bookings/booking-repair-card';

const BookingEditPage = () => {
    const api = useApi();
    const { bookingId } = useParams();
    const { resource: booking, state: resourceState } = useResource(() =>
        api.bookings.get(bookingId as string)
    );

    if (!booking) {
        return null;
    }

    const dateCreated = new Date(booking.createdAt);
    const submitButton: BasicCardButtonDef | null =
        booking.confirmation === unconfirmed
            ? {
                  label: 'Potvrdit',
                  action: () => true,
                  disabled: true,
              }
            : null;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                width: '40rem',
                margin: 'auto',
            }}
        >
            <BasicCard
                header="Rezervace"
                buttons={submitButton}
                style={{ flexGrow: '1' }}
            >
                <DataList>
                    <DataListItem label="Čas">
                        <BookingTimeWindowField booking={booking} />
                    </DataListItem>
                    <DataListItem label="Stav">
                        <BookingConfirmationTag booking={booking} />
                    </DataListItem>
                    <DataListItem label="Vytvořeno">
                        {`${dateCreated.toLocaleDateString()} ${dateCreated.toLocaleTimeString()}`}
                    </DataListItem>
                    <DataListItem label="Poznámka" italic={!booking.note}>
                        {booking.note || <i>žádná</i>}
                    </DataListItem>
                </DataList>
            </BasicCard>

            <BasicCard header="Kontakt">
                <DataList>
                    <DataListItem label="Jméno">
                        {booking.contact.name}
                    </DataListItem>
                    <DataListItem label="E-mail">
                        {booking.contact.email}
                    </DataListItem>
                    <DataListItem label="Telefon">
                        {booking.contact.phoneNumber}
                    </DataListItem>
                </DataList>
            </BasicCard>

            <BasicCard header="Vozidlo">
                <DataList>
                    <DataListItem label="Výrobce">
                        {booking.vehicle.manufacturer}
                    </DataListItem>
                    <DataListItem label="Model">
                        {booking.vehicle.model}
                    </DataListItem>
                    <DataListItem label="Typ">
                        {booking.vehicle.type}
                    </DataListItem>
                </DataList>
            </BasicCard>

            <BookingRepairCard booking={booking} />
        </div>
    );
};

export default BookingEditPage;
