import useApi from 'components/api/use-api';
import { useEffect, useState } from 'react';
import { ApiEndpoints } from 'components/api/use-api-endpoints';
import { SearchResultDef } from 'components/api/endpoints';

export type UseSearchParamsDef = {};

export default <T>(
    endpoint: (api: ApiEndpoints) => Promise<SearchResultDef<T>>,
    deps: any[] = []
) => {
    const api = useApi();
    const [result, setResult] = useState<SearchResultDef<T> | null>(null);

    useEffect(() => {
        endpoint(api).then(setResult);
    }, deps);

    return {
        items: result?.items,
    };
};
