import { useEffect, useState } from 'react';
import useLocalStorage from 'components/local-storage/use-local-storage';
import useApi from 'components/api/use-api';
import useApiKey from 'components/api/use-api-key';

export type AuthStateDef = 'authorizing' | 'authorized' | 'unauthorized';

export type ProvideAuthResultDef = {
    user: any;
    login: (username: string, password: string) => Promise<boolean>;
    logout: () => void;
    // state: AuthStateDef;
};

export default (): ProvideAuthResultDef => {
    const api = useApi();
    const [apiKey, setApiKey] = useApiKey();
    const [sessionKey, setSessionKey] = useLocalStorage<string | null>(
        'session'
    );
    const [user, setUser] = useState<any>(null);
    const [state, setState] = useState<AuthStateDef>(
        sessionKey ? 'authorizing' : 'unauthorized'
    );

    useEffect(() => {
        setApiKey(sessionKey || undefined);
        setUser(
            sessionKey
                ? {
                      key: sessionKey,
                  }
                : null
        );
    }, []);

    const login = async (username: string, password: string) => {
        try {
            const result = await api.auth.login(username, password, 1);

            setSessionKey(result.token);
            setApiKey(result.token);

            // Load user
            setUser({
                key: result.token,
            });

            return true;
        } catch {
            return false;
        }
    };

    const logout = () => {
        setSessionKey(null);
        setApiKey(undefined);
        setUser(null);
    };

    return {
        user,
        login,
        logout,
        // state,
    };
};
