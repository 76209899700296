import React from 'react';
import 'styles/components/_data-list.scss';
import { DataListItemProps } from 'components/data-list/data-list-item';

export type DataListProps = {
    children:
        | React.ReactElement<DataListItemProps>
        | React.ReactElement<DataListItemProps>[];
};

const DataList = ({ children }: DataListProps) => {
    return <ul className="c-data-list">{children}</ul>;
};

export default DataList;
