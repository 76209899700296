import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import useApi from 'components/api/use-api';
import { useNavigate } from 'react-router-dom';
import { TimeWindowDef } from 'components/api/endpoints/schedules';
import { EventInput } from '@fullcalendar/core';

export type TimeWindowCalendarProps = {
    height?: string;
};

const timeWindowToEvent = (tw: TimeWindowDef): EventInput => ({
    id: tw.id,
    start: tw.from,
    end: tw.to,
    date: tw.from,
    title: `${tw.scheduleName} (${tw.freeSlots}/${tw.slots})`,
});

const TimeWindowsCalendar = ({ height }: TimeWindowCalendarProps) => {
    const api = useApi();
    const navigate = useNavigate();
    // const [] = useLocalStorage<>('time-windows-calendar-view');

    const [timeWindows, setTimeWindows] = useState<TimeWindowDef[] | null>(
        null
    );

    useEffect(() => {
        api.schedules.getTimeWindows().then((r) => setTimeWindows(r.items));
    }, []);

    return (
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            height={height || '60rem'}
            headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            locale="cs-CZ"
            firstDay={1}
            displayEventTime={true}
            eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: false,
            }}
            buttonText={{
                day: 'den',
                month: 'měsíc',
                week: 'týden',
                today: 'dnes',
            }}
            events={timeWindows?.map(timeWindowToEvent)}
            eventClick={(e) => navigate(`/time-windows/${e.event.id}`)}
        />
    );
};

export default TimeWindowsCalendar;
