import React from 'react';
import 'styles/components/_radio-button.scss';
import 'styles/components/_list.scss';
import RadioButtonListItem, {
    RadioButtonListItemProps,
} from 'components/form/radio-button-list-item';

export interface RadioButtonListProps {
    items: RadioButtonListItemProps[];
    title?: string;
}

const RadioButtonList = (props: RadioButtonListProps) => {
    return (
        <div className="c-list">
            {props.title === undefined ? null : (
                <span className="c-list__title">{props.title}</span>
            )}
            <ul className="c-list__body">
                {props.items.map(RadioButtonListItem)}
            </ul>
        </div>
    );
};

export default RadioButtonList;
