import React from 'react';
import 'styles/components/_admin-layout.scss';
import { NavLink } from 'react-router-dom';

export interface MenuItemProps {
    text: string;
    link: string;
    icon: (props: React.HTMLAttributes<any>) => JSX.Element;
}

const MenuItem = (props: MenuItemProps) => {
    return (
        <NavLink
            className={({ isActive }) =>
                'c-admin-menu__item' +
                (isActive ? ' c-admin-menu__item--is-active' : '')
            }
            to={props.link}
            end={props.link === '/'}
        >
            {props.icon({
                className: 'c-admin-menu__link-icon',
            })}
            {props.text}
        </NavLink>
    );
};

export default MenuItem;
