export const removeAt = <T>(array: T[], index: number): T[] => {
    if (index < 0 || index >= array.length) {
        return array;
    }

    return [...array.slice(0, index), ...array.slice(index + 1)];
};

export const changeAt = <T>(array: T[], index: number, newValue: T): T[] => {
    if (index < 0 || index >= array.length) {
        return array;
    }

    return [...array.slice(0, index), newValue, ...array.slice(index + 1)];
};
