import React from 'react';
import {
    CzechHolidays,
    czechHolidaysLabels,
} from 'components/api/endpoints/schedules';
import Switch from 'components/form/switch';

export type CzechHolidaysListProps = {
    values: CzechHolidays[];
    onChange: (holidays: CzechHolidays[]) => void;
};

const CzechHolidaysList = ({ values, onChange }: CzechHolidaysListProps) => {
    return (
        <div>
            <ul>
                {Object.entries(czechHolidaysLabels).map(
                    ([holiday, holidayName]) => (
                        <li>
                            <Switch
                                id={`schedule-holiday-${holiday}`}
                                onChange={(e) =>
                                    onChange(
                                        (e.currentTarget.checked
                                            ? [...values, holiday]
                                            : values.filter(
                                                  (h) => h !== holiday
                                              )) as CzechHolidays[]
                                    )
                                }
                                checked={values.includes(
                                    holiday as CzechHolidays
                                )}
                            />
                            <label htmlFor={`schedule-holiday-${holiday}`}>
                                {holidayName}
                            </label>
                        </li>
                    )
                )}
            </ul>
        </div>
    );
};

export default CzechHolidaysList;
