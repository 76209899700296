import bookingsApi from 'components/api/endpoints/bookings';
import authApi from 'components/api/endpoints/auth';
import schedulesApi from 'components/api/endpoints/schedules';
import repairsApi from 'components/api/endpoints/repairs';
import vehiclesApi from 'components/api/endpoints/vehicles';
import { useMemo } from 'react';

export type ApiEndpoints = ReturnType<typeof createEndpoints>;

export type UseProvideApiParamsDef = {
    key?: string;
};

export const createEndpoints = (key?: string) => ({
    bookings: bookingsApi(key),
    auth: authApi(key),
    schedules: schedulesApi(key),
    repairs: repairsApi(key),
    vehicles: vehiclesApi(key),
});

export default (params?: UseProvideApiParamsDef): ApiEndpoints => {
    const api = useMemo<ApiEndpoints>(() => {
        return createEndpoints(params?.key);
    }, [params?.key]);

    return api;
};
