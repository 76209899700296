import { post } from 'components/api/endpoints/index';

export type LoginResultDef = {
    token: string;
};

export type AuthApiDef = ReturnType<typeof api>;

const api = (key?: string) => ({
    login: (email: string, password: string, tenantId: number) =>
        post<LoginResultDef>({
            route: 'auth/login',
            data: {
                email,
                password,
                tenantId,
            },
        }),
});

export default api;
