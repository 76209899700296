import { get, post, put, SearchResultDef } from 'components/api/endpoints';

export type UnidentifiedScheduleDef = {
    name: string;
    timeWindows: ScheduledTimeWindowDef[];
    czechHolidays: CzechHolidays[];
};

export type ScheduleDef = UnidentifiedScheduleDef & {
    id: string;
};

export type ScheduledTimeWindowDef = {
    day: number;
    from: string;
    to: string;
    slotsCount: number;
};

export type TimeWindowDef = {
    id: string;
    scheduleName: string;
    from: string;
    to: string;
    slots: number;
    freeSlots: 0;
    scheduleId: string;
};

export type AddScheduleResponseDef = {
    id: string;
};

export type CzechHolidays = typeof czechHolidays[number];

export type SchedulesApiDef = ReturnType<typeof api>;

export const czechHolidays = [
    'NewYear',
    'LaborDay',
    'LiberationDay',
    'CyrilAndMethodiusDay',
    'JanHusDay',
    'CzechStatehoodDay',
    'IndependenceDay',
    'StruggleForFreedomAndDemocracyDay',
    'Christmas',
    'Easter',
] as const;

export const czechHolidaysLabels: { [key in CzechHolidays]: string } = {
    NewYear: 'Nový rok',
    LaborDay: 'Svátek práce',
    LiberationDay: 'Den osvobození',
    CyrilAndMethodiusDay: 'Den slovanských věrozvěstů Cyrila a Metoděje',
    JanHusDay: 'Den upálení mistra Jana Husa',
    CzechStatehoodDay: 'Den české státnosti',
    IndependenceDay: 'Den nezávislosti',
    StruggleForFreedomAndDemocracyDay: 'Den boje za svobodu a demokracii',
    Christmas: 'Vánoce',
    Easter: 'Velikonoce',
} as const;

const api = (key?: string) => ({
    get: (id: string) =>
        get<ScheduleDef>({
            route: `schedules/${id}`,
            key,
        }),

    add: (schedule: UnidentifiedScheduleDef) =>
        post<AddScheduleResponseDef>({
            route: 'schedules',
            key,
            data: schedule,
        }),

    change: (id: string, schedule: UnidentifiedScheduleDef) =>
        put<ScheduleDef>({
            route: `schedules/${id}`,
            key,
            data: schedule,
        }),

    search: () =>
        get<SearchResultDef<ScheduleDef>>({
            route: 'schedules',
            key,
        }),

    getTimeWindows: () =>
        get<SearchResultDef<TimeWindowDef>>({
            route: 'schedules/time-windows',
            key,
        }),

    getTimeWindow: (id: string) =>
        get<TimeWindowDef>({
            route: `schedules/time-windows/${id}`,
            key,
        }),
});

export default api;
