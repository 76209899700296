import React from 'react';
import 'styles/components/_card.scss';

export type CardProps = React.HTMLAttributes<HTMLDivElement> & {
    className?: string;
    children?: React.ReactNode;
};

const Card = ({ className, children, ...additionalProps }: CardProps) => {
    return (
        <div {...additionalProps} className={`c-card ${className}`}>
            {children}
        </div>
    );
};

export default Card;
