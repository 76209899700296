import React from 'react';
import 'styles/components/_admin-menu.scss';
import MenuItem from './menu-item';
import {
    CalendarPlus,
    CalendarWeek,
    CardChecklist,
    CarFrontFill,
    Grid,
    Pass,
    People,
    Person,
} from 'react-bootstrap-icons';

const Menu = () => {
    return (
        <div className="c-admin-menu">
            <div className="c-admin-menu__item-list">
                <MenuItem
                    text={'Základní přehled'}
                    link={'/'}
                    icon={(p) => <Grid {...p} />}
                />
                <MenuItem
                    text={'Opravenky'}
                    link={'/repairs'}
                    icon={(p) => <Pass {...p} />}
                />
                <MenuItem
                    text={'Rezervace'}
                    link={'/bookings'}
                    icon={(p) => <CardChecklist {...p} />}
                />
                <MenuItem
                    text={'Časová okna'}
                    link={'/time-windows'}
                    icon={(p) => <CalendarWeek {...p} />}
                />
                <MenuItem
                    text={'Rozvrhy'}
                    link={'/schedules'}
                    icon={(p) => <CalendarPlus {...p} />}
                />
                <MenuItem
                    text={'Vozidla a komponenty'}
                    link={'/vehicles'}
                    icon={(p) => <CarFrontFill {...p} />}
                />
                <MenuItem
                    text={'Zákazníci'}
                    link={'/customers'}
                    icon={(p) => <Person {...p} />}
                />
                <MenuItem
                    text={'Správa účtů'}
                    link={'/users'}
                    icon={(p) => <People {...p} />}
                />
            </div>
        </div>
    );
};

export default Menu;
