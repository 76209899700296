import React from 'react';
import { TextField } from 'components/form';
import HighlightArea from 'components/highlight/highlight-area';
import { RepairDef, VinCheck } from 'components/api/endpoints/repairs';
import useResource from 'components/api/use-resource';
import RepairVinRecordDetail from 'components/repairs/repair-vin-record-detail';
import CardContent from 'components/card/card-content';
import { Card } from 'components/card';
import CardHeader from 'components/card/card-header';

export type RepairVinRecordCardProps = {
    repair: RepairDef;
};

const RepairVinRecordCard = ({ repair }: RepairVinRecordCardProps) => {
    const { resource: vinRecord } = useResource((api) =>
        repair.vehicle.vinCheck === VinCheck.found
            ? api.vehicles.getVinRecord(repair.vehicle.vin as string)
            : null
    );

    return (
        <Card>
            <CardHeader>Podrobné informace o vozidle</CardHeader>
            <CardContent style={{ maxHeight: '20rem', overflowY: 'scroll' }}>
                {repair.vehicle.vin ? (
                    vinRecord ? (
                        <RepairVinRecordDetail record={vinRecord} />
                    ) : null
                ) : (
                    <HighlightArea>
                        <h2>Zjistěte informace o vozidle</h2>
                        <p>
                            Pro podrobné informace o vozidle stačí pouze zadat
                            jeho VIN.
                        </p>
                        <TextField
                            placeholder={'VIN'}
                            value={repair.vehicle.vin}
                        />
                    </HighlightArea>
                )}
            </CardContent>
        </Card>
    );
};

export default RepairVinRecordCard;
