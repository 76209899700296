import React, { useEffect, useState } from 'react';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { formatDaytime } from 'utils/time';
import { BookingDef, unconfirmed } from 'components/api/endpoints/bookings';
import { TimeWindowDef } from 'components/api/endpoints/schedules';
import useApi from 'components/api/use-api';
import TimeWindowSelectIcon from 'components/bookings/time-window-select-icon';

export type BookingTimeWindowFieldProps = {
    booking: BookingDef;
};

const BookingTimeWindowField = ({ booking }: BookingTimeWindowFieldProps) => {
    const api = useApi();

    const dateFrom = new Date(booking.from);
    const dateTo = new Date(booking.to);

    const [timeWindow, setTimeWindow] = useState<TimeWindowDef | null | false>(
        null
    );

    useEffect(() => {
        if (booking.timeWindowId) {
            api.schedules
                .getTimeWindow(booking.timeWindowId)
                .then(setTimeWindow)
                .catch(() => setTimeWindow(false));
        }
    }, []);

    const timeWindowUnvailable = timeWindow
        ? timeWindow.freeSlots === 0
        : false;

    return (
        <>
            {timeWindowUnvailable ? (
                <ExclamationTriangleFill style={{ color: '#ffc700' }} />
            ) : null}
            {timeWindow ? (
                <Link
                    to={`/time-windows/${timeWindow.id}`}
                    style={
                        timeWindowUnvailable ? { color: '#ffc700' } : undefined
                    }
                >
                    {`${dateFrom.toLocaleDateString()} ${formatDaytime(
                        dateFrom
                    )}-${formatDaytime(dateTo)}`}
                </Link>
            ) : (
                `${dateFrom.toLocaleDateString()} ${formatDaytime(
                    dateFrom
                )}-${formatDaytime(dateTo)}`
            )}

            {booking.confirmation === unconfirmed ? (
                <TimeWindowSelectIcon />
            ) : null}
        </>
    );
};

export default BookingTimeWindowField;
