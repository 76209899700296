import { get } from 'components/api/endpoints/index';

export type VinRecordDef = {
    vin: string;
    vehicleId: number;
    make: string;
    model: string;
    modelYear: number;
    productType: string;
    body: string;
    series: string;
    drive: string;
    vehicleSpecification: string;
    engineDisplacement: number;
    enginePowerKw: number;
    enginePowerHp: number;
    fuelTypePrimary: string;
    engineCode: string;
    transmission: string;
    numberOfGears: number;
    emissionStandard: string;
    manufacturer: string;
    manufacturerAddress: string;
    plantCountry: string;
    market: string;
    made: string;
    registered: string;
    productionStarted: number;
    productionStopped: number;
    engineRpm: number;
    engineManufacturer: string;
    engineType: string;
    fuelConsumptionCombined: number;
    fuelConsumptionExtraUrban: number;
    fuelConsumptionUrban: number;
    co2Emission: number;
    axleRatio: number;
    numberWheels: number;
    numberOfAxles: number;
    numberOfDoors: number;
    numberOfSeats: string;
    frontBrakes: string;
    brakeSystem: string;
    suspension: string;
    steering: string;
    steeringType: string;
    wheelRimsSize: string;
    wheelSize: string;
    wheelbase: number;
    height: number;
    length: number;
    width: number;
    rearOverhang: number;
    trackFront: number;
    trackRear: number;
    maxSpeed: number;
    weightEmpty: number;
    maxWeight: number;
    maxRoofLoad: number;
    permittedTrailerLoadWithoutBrakes: number;
    color: string;
    checkDigit: string;
    sequentialNumber: string;
};

const api = (key?: string) => ({
    getVinRecord: (vin: string) =>
        get<VinRecordDef>({
            route: `vehicles/vin-records/${vin}`,
            key,
        }),
});

export default api;
