import React, { useId } from 'react';
import 'styles/components/_radio-button.scss';

export interface RadioButtonProps {
    name?: string;
    label?: string;
    description?: string;
}

const RadioButton = (props: RadioButtonProps) => {
    const id = useId();

    return (
        <div className="c-radio-button">
            <input
                id={id}
                type="radio"
                name={props.name}
                className="c-radio-button__input"
            />
            {props.description === undefined &&
            props.label === undefined ? null : (
                <label className="c-radio-button__label-container" htmlFor={id}>
                    {props.label === undefined ? null : (
                        <span className="c-radio-button__label">
                            {props.label}
                        </span>
                    )}
                    {props.description === undefined ? null : (
                        <span className="c-radio-button__description">
                            {props.description}
                        </span>
                    )}
                </label>
            )}
        </div>
    );
};

export default RadioButton;
