import React from 'react';
import { Card } from 'components/card';
import { Button, TextField } from 'components/form';
import CardHeader from 'components/card/card-header';
import CardContent from 'components/card/card-content';
import CardFooter from 'components/card/card-footer';
import RadioButtonList from 'components/form/radio-button-list';

const InviteUserForm = () => {
    return (
        <Card>
            <CardHeader>Pozvat nového uživatele</CardHeader>
            <CardContent>
                <TextField
                    label="Email"
                    description="Emailová adresa, na kterou bude záslán odkaz s registračním formulářem pro nového uživatele."
                />
                <RadioButtonList
                    title="Oprávnění"
                    items={[
                        {
                            value: 'mechanic',
                            label: 'Mechanik',
                            description: 'Nahlížení do opravenek.',
                        },
                        {
                            value: 'manager',
                            label: 'Manažer',
                            description: 'Správa opravenek a rezervací.',
                        },
                        {
                            value: 'super-admin',
                            label: 'Administrátor',
                            description: 'Kompletní správa nad celým systém.',
                        },
                    ]}
                />
            </CardContent>
            <CardFooter>
                <Button>Pozvat</Button>
            </CardFooter>
        </Card>
    );
};

export default InviteUserForm;
