import React from 'react';
import InviteUserForm from 'components/users/invite-user-form';
import 'styles/components/_users-dashboard.scss';

const UsersDashboard = () => {
    return (
        <div className="c-users-dashboard">
            <InviteUserForm />
        </div>
    );
};

export default UsersDashboard;
