import React from 'react';
import { Login } from 'components/login';
import Routes from './routes';
import useAuth from 'components/auth/use-auth';
import { SnackbarProvider } from 'notistack';

const App = () => {
    const auth = useAuth();

    return (
        <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {auth.user ? <Routes /> : <Login />}
        </SnackbarProvider>
    );
};

export default App;
