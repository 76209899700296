import React from 'react';
import { ScheduleDef } from 'components/api/endpoints/schedules';
import DataTable, { ColumnDef } from 'components/table/data-table';
import { PencilSquare } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import Cards from 'components/card/cards';
import useSearch from 'components/api/use-search';
import CreateScheduleButton from 'components/schedules/create-schedule-button';
import { Card } from 'components/card';
import CardHeader from 'components/card/card-header';
import CardContent from 'components/card/card-content';
import CardFooter from 'components/card/card-footer';

const columns: ColumnDef<ScheduleDef>[] = [
    {
        accessor: 'name',
        header: 'Název',
    },
    {
        accessor: (s) => s.timeWindows.length,
        header: 'Časová okna',
        align: 'center',
        width: 'shrink',
    },
    {
        width: 'shrink',
        accessor: (s) => (
            <Link to={`/schedules/${s.id}`}>
                <PencilSquare />
            </Link>
        ),
    },
];

const SchedulesDashboardPage = () => {
    const { items: schedules } = useSearch((api) => api.schedules.search());

    return (
        <Cards>
            <Card>
                <CardHeader>Rozvrhy</CardHeader>
                <CardContent>
                    <DataTable
                        columns={columns}
                        data={schedules}
                        style={{ width: '40rem' }}
                    />
                </CardContent>
                <CardFooter>
                    <CreateScheduleButton />
                </CardFooter>
            </Card>
        </Cards>
    );
};

export default SchedulesDashboardPage;
