import React, { useId, useState } from 'react';
import 'styles/components/_text-field.scss';
import { Optional } from 'utils/types';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

export type TextFieldProps = {
    label?: string;
    onChange?: (value: string) => void;
    value?: Optional<string>;
    description?: string;
    placeholder?: string;
    type?: 'textarea' | 'text' | 'password' | 'number';
};

const TextField = (props: TextFieldProps) => {
    const id = useId();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="c-text-field">
            {props?.label ? (
                <label className="c-text-field__label" htmlFor={id}>
                    {props.label}
                </label>
            ) : null}
            {props.type === 'textarea' ? (
                <textarea
                    className="c-text-field__input c-text-field__input--textarea"
                    id={id}
                    onInput={(e) => props.onChange?.(e.currentTarget.value)}
                    value={props.value || ''}
                ></textarea>
            ) : (
                <div className="c-text-field__input-container">
                    <input
                        className={`c-text-field__input c-text-field__input--type-${
                            props.type || 'text'
                        }`}
                        id={id}
                        onInput={(e) => props.onChange?.(e.currentTarget.value)}
                        value={props.value || ''}
                        type={showPassword ? 'text' : props.type}
                        placeholder={props.placeholder}
                    />
                    {props.type === 'password' ? (
                        showPassword ? (
                            <Eye
                                className="c-text-field__show-password-icon"
                                onClick={() => setShowPassword(false)}
                            />
                        ) : (
                            <EyeSlash
                                className="c-text-field__show-password-icon"
                                onClick={() => setShowPassword(true)}
                            />
                        )
                    ) : null}
                </div>
            )}
            {props.description ? (
                <p className="c-text-field__description">{props.description}</p>
            ) : null}
        </div>
    );
};

export default TextField;
