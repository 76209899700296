import { get, post, SearchResultDef } from 'components/api/endpoints';

export type RepairDef = {
    id: string;
    contact: {
        name: string;
        email: string;
        phoneNumber: string;
    };
    vehicle: {
        id: string;
        manufacturer: string;
        model: string;
        type: string;
        vin: string | null;
        vinCheck: VinCheck;
    };
    note: string | null;
    solution: string | null;
    items: RepairItemDef[];
    defects: RepairDefectDef[];
};

export type RepairItemDef = {
    name: string;
    quantity: number;
    price: number;
};

export type RepairDefectDef = {
    description: string;
    code?: string | null;
};

export enum VinCheck {
    none = 0,
    found = 1,
}

const api = (key?: string) => ({
    search: () =>
        get<SearchResultDef<RepairDef>>({
            route: 'repairs',
            key,
        }),

    get: (repairId: string) =>
        get<RepairDef>({
            route: `repairs/${repairId}`,
            key,
        }),

    updateItems: (repairId: string, items: RepairItemDef[]) =>
        post({
            route: `repairs/${repairId}/items`,
            key,
            data: items,
        }),

    updateDefects: (repairId: string, items: RepairDefectDef[]) =>
        post({
            route: `repairs/${repairId}/defects`,
            key,
            data: items,
        }),

    updateSolution: (repairId: string, solution: string) =>
        post({
            route: `repairs/${repairId}/solution`,
            key,
            data: { value: solution },
        }),
});

export default api;
