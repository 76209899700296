import React from 'react';

export interface CardHeaderProps {
    children?: React.ReactNode;
}

const CardHeader = ({ children }: CardHeaderProps) => {
    return <div className="c-card__header">{children}</div>;
};

export default CardHeader;
