import React, { useEffect, useState } from 'react';
import BasicCard, { BasicCardButtonDef } from 'components/card/basic-card';
import useApi from 'components/api/use-api';
import { useParams } from 'react-router-dom';
import useResource from 'components/api/use-resource';
import {
    ScheduleDef,
    UnidentifiedScheduleDef,
} from 'components/api/endpoints/schedules';
import ScheduledTimeWindowsCalendar from 'components/schedules/scheduled-time-windows-calendar';
import { replaceAt } from 'components/array';
import { TextField } from 'components/form';
import CzechHolidaysList from 'components/schedules/czech-holidays-list';

const ScheduleEditPage = () => {
    const api = useApi();
    const { scheduleId } = useParams();
    const { resource: originalSchedule, state: scheduleState } = useResource(
        () => api.schedules.get(scheduleId as string)
    );
    const [schedule, setSchedule] = useState<ScheduleDef | null>(null);

    useEffect(() => {
        if (originalSchedule) {
            setSchedule(originalSchedule);
        }
    }, [scheduleState]);

    if (scheduleState !== 'found' || !schedule) {
        return null;
    }

    const saveScheduleBtn: BasicCardButtonDef = {
        label: 'Uložit',
        action: () => {
            if (schedule) {
                const { id, ...scheduleWithoutId } = schedule;
                api.schedules.change(
                    id,
                    scheduleWithoutId as UnidentifiedScheduleDef
                );
            }
        },
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
                maxWidth: '50rem',
                margin: 'auto',
            }}
        >
            <BasicCard header="Nastavení rozvrhu" buttons={saveScheduleBtn}>
                <TextField
                    label={'Název'}
                    value={schedule.name}
                    onChange={(name) => setSchedule({ ...schedule, name })}
                />
                <CzechHolidaysList
                    values={schedule.czechHolidays}
                    onChange={(czechHolidays) =>
                        setSchedule({ ...schedule, czechHolidays })
                    }
                />
                <ScheduledTimeWindowsCalendar
                    timeWindows={schedule?.timeWindows || []}
                    onAdd={(tw) =>
                        setSchedule({
                            ...schedule,
                            timeWindows: [...schedule.timeWindows, tw],
                        })
                    }
                    onChange={(i, tw) =>
                        setSchedule({
                            ...schedule,
                            timeWindows: replaceAt(schedule.timeWindows, i, tw),
                        })
                    }
                    onRemove={() => void 1}
                />
            </BasicCard>
        </div>
    );
};

export default ScheduleEditPage;
