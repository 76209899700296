import React from 'react';
import 'styles/components/_card.scss';

export type CardContentProps = React.HTMLAttributes<HTMLDivElement> & {
    children?: React.ReactNode;
};

const CardContent = ({ children, ...additionalProps }: CardContentProps) => {
    return (
        <div {...additionalProps} className="c-card__content">
            {children}
        </div>
    );
};

export default CardContent;
