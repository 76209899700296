import React from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ScheduledTimeWindowDef } from 'components/api/endpoints/schedules';
import { EventImpl } from '@fullcalendar/core/internal';
import { addHours, formatDaytime } from 'utils/time';

export type ScheduledTimeWindowsCalendarProps = {
    timeWindows: ScheduledTimeWindowDef[];
    onAdd: (timeWindow: ScheduledTimeWindowDef) => void;
    onChange: (index: number, timeWindow: ScheduledTimeWindowDef) => void;
    onRemove: (index: number) => void;
};

const weekdays = ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'];

const timeWindowToEvent = (tw: ScheduledTimeWindowDef, i: number) => ({
    id: i.toString(),
    title: `sloty: ${tw.slotsCount}`,
    daysOfWeek: [tw.day],
    startTime: tw.from,
    endTime: tw.to,
});

const eventToTimeWindow = (e: EventImpl): ScheduledTimeWindowDef => ({
    day: e.start?.getDay() as number,
    from: formatDaytime(e.start as Date),
    to: formatDaytime(e.end as Date),
    slotsCount: 2,
});

const createTimeWindow = (date: Date): ScheduledTimeWindowDef => ({
    day: date.getDay() as number,
    from: formatDaytime(date),
    to: formatDaytime(addHours(date, 1)),
    slotsCount: 2,
});

const ScheduledTimeWindowsCalendar = ({
    timeWindows,
    onAdd,
    onRemove,
    onChange,
}: ScheduledTimeWindowsCalendarProps) => {
    return (
        <FullCalendar
            editable
            droppable
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            height="40rem"
            slotDuration="00:15:00"
            firstDay={1}
            allDaySlot={false}
            headerToolbar={{
                left: '',
                center: '',
                right: '',
            }}
            events={timeWindows.map((tw, i) => timeWindowToEvent(tw, i))}
            locale="cs-CZ"
            dayHeaderContent={(arg) => weekdays[arg.date.getDay()]}
            dateClick={(e) =>
                e.jsEvent.detail === 2 ? onAdd(createTimeWindow(e.date)) : null
            }
            eventClick={(e) =>
                e.jsEvent.detail === 2 ? onRemove(Number(e.event.id)) : null
            }
            eventChange={({ event }) =>
                onChange(Number(event.id), eventToTimeWindow(event))
            }
        />
    );
};

export default ScheduledTimeWindowsCalendar;
