import { useEffect, useState } from 'react';
import useApi from 'components/api/use-api';
import { ApiEndpoints } from 'components/api/use-api-endpoints';

export type UseResourceResultDef<T> = {
    state: 'loading' | 'found' | 'notfound';
    resource?: T;
};

export default <T>(
    loadResource: (api: ApiEndpoints) => Promise<T> | null | undefined,
    dependencies = []
) => {
    const api = useApi();
    const [resource, setResource] = useState<UseResourceResultDef<T>>({
        state: 'loading',
    });

    useEffect(() => {
        const promise = loadResource(api)
            ?.then((r) =>
                setResource({
                    state: 'found',
                    resource: r,
                })
            )
            ?.catch(() =>
                setResource({
                    state: 'notfound',
                })
            );

        if (!promise) {
            setResource({
                state: 'notfound',
            });
        }
    }, dependencies);

    return resource;
};
