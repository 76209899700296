import React from 'react';
import 'styles/components/_tag.scss';
import { className } from 'utils/components';

export type TypeProps = {
    children: React.ReactNode;
    type?: 'default' | 'success' | 'warning';
};

const Tag = ({ children, type }: TypeProps) => {
    return (
        <span
            className={className(
                'c-tag',
                type && type !== 'default' ? `c-tag--${type}` : null
            )}
        >
            {children}
        </span>
    );
};

export default Tag;
