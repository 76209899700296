import React from 'react';
import BasicCard from 'components/card/basic-card';
import useSearch from 'components/api/use-search';
import BookingsTable from 'components/bookings/bookings-table';

const Dashboard = () => {
    const { items: bookings } = useSearch((api) => api.bookings.search());

    return (
        <div>
            <BasicCard header="Nové rezervace">
                <BookingsTable filter={{ confirmation: 0 }} />
            </BasicCard>
        </div>
    );
};

export default Dashboard;
