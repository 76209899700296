import React from 'react';

export type GroupedContentDetailProps = {
    children?: React.ReactNode;
};

const GroupedContentDetail = ({ children }: GroupedContentDetailProps) => {
    return <div className="c-grouped-content__detail-content">{children}</div>;
};

export default GroupedContentDetail;
