import { get, post, SearchResultDef } from 'components/api/endpoints';

export type BookingDef = {
    confirmation: number;
    timeWindowId: string | null;
    repairId: string | null;
    id: string;
    note: string | null;
    from: string;
    to: string;
    createdAt: string;
    contact: {
        name: string;
        phoneNumber: string;
        email: string;
    };
    vehicle: {
        id: string;
        manufacturer: string;
        model: string;
        type: number;
    };
};

export type SearchFilterDef = {
    confirmation?: number | number[];
};

export type CreateRepairResultDef = {
    repairId: string;
};

export const confirmed = 1;

export const unconfirmed = 0;

export type BookingsApiDef = ReturnType<typeof api>;

const api = (key?: string) => ({
    search: (filter?: SearchFilterDef) =>
        get<SearchResultDef<BookingDef>>({
            route: 'bookings',
            params: filter,
            key,
        }),
    get: (bookingId: string) =>
        get<BookingDef>({
            route: `bookings/${bookingId}`,
            key,
        }),

    createRepair: (bookingId: string) =>
        post<CreateRepairResultDef>({
            route: `bookings/${bookingId}/repair`,
            key,
        }),
});

export default api;
