import React from 'react';
import { BookingDef, confirmed } from 'components/api/endpoints/bookings';
import Tag from 'components/tags/tag';

export type BookingConfirmationTagProps = {
    booking: BookingDef;
};

const BookingConfirmationTag = ({ booking }: BookingConfirmationTagProps) => {
    if (booking.confirmation == confirmed) {
        return <Tag type="success">potvrzeno</Tag>;
    }

    return <Tag type="warning">nepotvrzeno</Tag>;
};

export default BookingConfirmationTag;
