import React from 'react';
import 'styles/components/_table.scss';
import { Optional } from 'utils/types';
import { className } from 'utils/components';
import { Flatten, lookup } from 'utils/types/flatten';

export type CellAlignmentDef = 'center' | 'left' | 'right';

export type ColumnDef<T = any> = {
    header?: string;
    accessor?: string | keyof Flatten<T> | ((data: T) => any);
    width?: 'shrink' | 'auto';
    align?: CellAlignmentDef;
};

export type DataTableProps = React.HTMLAttributes<HTMLTableElement> & {
    columns?: ColumnDef[];
    data?: Optional<any[]>;
};

const getAlignmentClass = (alignment?: CellAlignmentDef) =>
    alignment ? `c-table__data-cell--align-${alignment}` : null;

const DataTable = ({columns, data, ...tableProps}: DataTableProps) => {
    return (
        <table {...tableProps} className="c-table">
            <thead>
            <tr>
                {columns?.map((col) => (
                    <th
                        className={className('c-table__header-cell', {
                            'c-table__header-cell--shrink':
                                col.width === 'shrink',
                        })}
                    >
                        {col.header}
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data?.map((data) => (
                <tr className="c-table__data-row">
                    {columns?.map((col) => {
                        let value;

                        if (col.accessor) {
                            value =
                                typeof col.accessor === 'function'
                                    ? col.accessor(data)
                                    : // @ts-ignore
                                    lookup(data, col.accessor);
                        }

                        return (
                            <td
                                className={className(
                                    'c-table__data-cell',
                                    getAlignmentClass(col.align),
                                    {
                                        'c-table__data-cell--shrink':
                                            col.width === 'shrink',
                                    }
                                )}
                            >
                                {value}
                            </td>
                        );
                    })}
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default DataTable;
