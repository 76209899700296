import { useSnackbar } from 'notistack';

export const useHandlePromise = () => {
    const { enqueueSnackbar } = useSnackbar();

    return <T>(
        promise: Promise<T>,
        successMessage: string,
        errorMessage: string
    ): Promise<T> => {
        return promise
            .then((value) => {
                enqueueSnackbar(successMessage, { variant: 'success' });

                return value;
            })
            .catch((value) => {
                enqueueSnackbar(errorMessage, { variant: 'error' });

                return value;
            });
    };
};
