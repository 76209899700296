import React from 'react';
import BasicCard from 'components/card/basic-card';
import RepairsTable from 'components/repairs/repairs-table';

const RepairsDashboard = () => {
    return (
        <div>
            <BasicCard>
                <RepairsTable />
            </BasicCard>
        </div>
    );
};

export default RepairsDashboard;
