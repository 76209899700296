import React from 'react';
import { Dashboard } from 'components/dashboard';
import { RepairsDashboard } from 'components/repairs';
import { RouteObject, useRoutes } from 'react-router-dom';
import Admin from 'components/administration/admin';
import { UsersDashboard } from 'components/users';
import BookingsDashboardPage from 'components/bookings/bookings-dashboard-page';
import SchedulesDashboardPage from 'components/schedules/schedules-dashboard-page';
import ScheduleEditPage from 'components/schedules/schedule-edit-page';
import TimeWindowsDashboardPage from 'components/time-windows/time-windows-dashboard-page';
import TimeWindowEditPage from 'components/time-windows/time-window-edit-page';
import BookingEditPage from 'components/bookings/booking-edit-page';
import RepairEditPage from 'components/repairs/repair-edit-page';

interface ModuleDef extends RouteObject {
    title: string;
    children?: ModuleDef[];
}

const mapPageRoute = (route: ModuleDef): RouteObject => ({
    ...route,
    element: <Admin title={route.title} component={route.element} />,
    children: route.children?.map(mapPageRoute),
});

const pageRoutes: ModuleDef[] = [
    {
        id: 'dashboard',
        title: 'Základní přehled',
        path: '/',
        element: <Dashboard />,
    },
    {
        id: 'repairs',
        title: 'Opravenky',
        path: '/repairs',
        element: <RepairsDashboard />,
    },
    {
        id: 'editRepair',
        title: 'Opravenka',
        path: '/repairs/:repairId',
        element: <RepairEditPage />,
    },
    {
        id: 'users',
        title: 'Správa účtů',
        path: '/users',
        element: <UsersDashboard />,
    },
    {
        id: 'bookings',
        title: 'Přehled rezervací',
        path: '/bookings',
        element: <BookingsDashboardPage />,
    },
    {
        id: 'editBooking',
        title: 'Rezervace',
        path: '/bookings/:bookingId',
        element: <BookingEditPage />,
    },
    {
        id: 'schedules',
        title: 'Rozvrhy',
        path: '/schedules',
        element: <SchedulesDashboardPage />,
    },
    {
        id: 'editSchedule',
        title: 'Rozvrh',
        path: '/schedules/:scheduleId',
        element: <ScheduleEditPage />,
    },
    {
        id: 'timeWindows',
        title: 'Časová okna',
        path: '/time-windows',
        element: <TimeWindowsDashboardPage />,
    },
    {
        id: 'editTimeWindow',
        title: 'Časové okno',
        path: '/time-windows/:timeWindowId',
        element: <TimeWindowEditPage />,
    },
    {
        id: 'vehicles',
        title: 'Vozidla a komponenty',
        path: '/vehicles',
        element: null,
    },
];

export const routes: RouteObject[] = pageRoutes.map(mapPageRoute);

const Routes = () => {
    return useRoutes(routes);
};

export default Routes;
