import React from 'react';

export type GroupedContentItemProps = {
    name: string;
    children?: React.ReactNode;
};

const GroupedContentItem = ({ name, children }: GroupedContentItemProps) => {
    return <>{children}</>;
};

export default GroupedContentItem;
