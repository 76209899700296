import React from 'react';
import { VinRecordDef } from 'components/api/endpoints/vehicles';
import DataList from 'components/data-list/data-list';
import DataListItem from 'components/data-list/data-list-item';

export type RepairVinRecordDetailProps = {
    record: VinRecordDef;
};
const RepairVinRecordDetail = ({ record }: RepairVinRecordDetailProps) => {
    return (
        <div>
            <h3>Základní informace</h3>
            <DataList>
                <DataListItem label="Značka">{record.make}</DataListItem>
                <DataListItem label="Model">{record.model}</DataListItem>
                <DataListItem label="Výrobce">
                    {record.manufacturer}
                </DataListItem>
                <DataListItem label="VIN">{record.vin}</DataListItem>
                <DataListItem label="Vehicle ID">
                    {record.vehicleId}
                </DataListItem>
                <DataListItem label="Rok výroby">
                    {record.modelYear}
                </DataListItem>
                <DataListItem label="Typ vozidla">
                    {record.productType}
                </DataListItem>
                <DataListItem label="Karoserie">{record.body}</DataListItem>
                <DataListItem label="Řada">{record.series}</DataListItem>
                <DataListItem label="Pohon">{record.drive}</DataListItem>
            </DataList>

            <h3>Specifikace motoru</h3>
            <DataList>
                <DataListItem label="Objem motoru">
                    {record.engineDisplacement}
                </DataListItem>
                <DataListItem label="Výkon motoru (kW)">
                    {record.enginePowerKw}
                </DataListItem>
                <DataListItem label="Výkon motoru (HP)">
                    {record.enginePowerHp}
                </DataListItem>
                <DataListItem label="Hlavní palivo">
                    {record.fuelTypePrimary}
                </DataListItem>
                <DataListItem label="Kód motoru">
                    {record.engineCode}
                </DataListItem>
                <DataListItem label="Převodovka">
                    {record.transmission}
                </DataListItem>
                <DataListItem label="Počet rychlostí">
                    {record.numberOfGears}
                </DataListItem>
                <DataListItem label="Emisní norma">
                    {record.emissionStandard}
                </DataListItem>
                <DataListItem label="Otáčky motoru">
                    {record.engineRpm}
                </DataListItem>
                <DataListItem label="Výrobce motoru">
                    {record.engineManufacturer}
                </DataListItem>
                <DataListItem label="Typ motoru">
                    {record.engineType}
                </DataListItem>
            </DataList>

            <h3>Výroba</h3>
            <DataList>
                <DataListItem label="Výrobce">
                    {record.manufacturer}
                </DataListItem>
                <DataListItem label="Adresa výrobce">
                    {record.manufacturerAddress}
                </DataListItem>
                <DataListItem label="Země výroby">
                    {record.plantCountry}
                </DataListItem>
                <DataListItem label="Datum výroby">{record.made}</DataListItem>
                <DataListItem label="Výroba zahájena">
                    {record.productionStarted}
                </DataListItem>
                <DataListItem label="Výroba ukončena">
                    {record.productionStopped}
                </DataListItem>
            </DataList>

            <h3>Další informace</h3>
            <DataList>
                <DataListItem label="Trh">{record.market}</DataListItem>
                <DataListItem label="Datum registrace">
                    {record.registered}
                </DataListItem>

                <DataListItem label="Spotřeba kombinovaná">
                    {record.fuelConsumptionCombined}
                </DataListItem>
                <DataListItem label="Spotřeba mimo město">
                    {record.fuelConsumptionExtraUrban}
                </DataListItem>
                <DataListItem label="Spotřeba ve městě">
                    {record.fuelConsumptionUrban}
                </DataListItem>
            </DataList>
        </div>
    );
};

export default RepairVinRecordDetail;
