import React from 'react';
import 'styles/components/_card.scss';

export interface CardFooterProps {
    children?: JSX.Element | JSX.Element[] | string | string[];
}

const CardFooter = ({ children }: CardFooterProps) => {
    return <div className="c-card__footer">{children}</div>;
};

export default CardFooter;
