import React from 'react';
import BasicCard, { BasicCardButtonDef } from 'components/card/basic-card';
import useList, { getPayloads } from 'components/form/use-list';
import 'styles/components/_repair-defects.scss';
import { Button, TextField } from 'components/form';
import useApi from 'components/api/use-api';
import { RepairDef, RepairDefectDef } from 'components/api/endpoints/repairs';
import { useSnackbar } from 'notistack';

export type RepairDefectsCardProps = {
    repair: RepairDef;
};

const RepairDefectsCard = ({ repair }: RepairDefectsCardProps) => {
    const api = useApi();
    const { enqueueSnackbar } = useSnackbar();
    const { items, add } = useList<RepairDefectDef>(repair.defects);

    const saveBtn: BasicCardButtonDef = {
        label: 'Uložit',
        action: () =>
            api.repairs
                .updateDefects(repair.id, getPayloads(items))
                .then(() =>
                    enqueueSnackbar('Závady byly uloženy.', {
                        variant: 'success',
                    })
                )
                .catch(() =>
                    enqueueSnackbar('Závady se nepodařilo uložit.', {
                        variant: 'error',
                    })
                ),
    };

    return (
        <BasicCard header="Seznam závad" buttons={saveBtn}>
            <table className="c-table">
                <thead>
                    <tr>
                        <th className="c-table__header-cell">Kód</th>
                        <th className="c-table__header-cell">Popis</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item) => (
                        <tr>
                            <td className="c-table__data-cell">
                                <TextField
                                    value={item.payload.code}
                                    onChange={(code) =>
                                        item.update({ ...item.payload, code })
                                    }
                                />
                            </td>
                            <td className="c-table__data-cell">
                                <TextField
                                    value={item.payload.description}
                                    onChange={(description) =>
                                        item.update({
                                            ...item.payload,
                                            description,
                                        })
                                    }
                                />
                            </td>
                            <td className="c-table__data-cell c-table__data-cell--shrink">
                                <Button btnType="grey" onClick={item.remove}>
                                    x
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Button
                onClick={() =>
                    add({
                        code: '',
                        description: '',
                    })
                }
            >
                Přidat
            </Button>
        </BasicCard>
    );
};

export default RepairDefectsCard;
