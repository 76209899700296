import React from 'react';
import { className } from 'utils/components';
import 'styles/components/_cards.scss';

export type CardsProps = {
    children?: React.ReactNode;
    flow?: 'horizontal' | 'vertical';
};

const Cards = ({ children, flow }: CardsProps) => {
    return (
        <div
            className={className('c-cards', {
                'c-cards--horizontal': flow === 'horizontal' || !flow,
                'c-cards--vertical': flow === 'vertical',
            })}
        >
            {children}
        </div>
    );
};

export default Cards;
