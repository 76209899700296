import React, { useState } from 'react';
import 'styles/components/_grouped-content.scss';
import GroupedContentDetail from 'components/content/grouped-content-detail';
import GroupedContentItem, {
    GroupedContentItemProps,
} from 'components/content/grouped-content-item';
import { className } from 'utils/components';

export type GroupedContentProps = {
    children?: React.ReactElement[];
};

const isItemComponent = (
    component: React.ReactElement
): component is React.ReactElement<GroupedContentItemProps> =>
    component.type === GroupedContentItem;
const GroupedContent = ({ children }: GroupedContentProps) => {
    const [activeItemIndex, setActiveItemIndex] = useState(0);

    const detail = children?.find((c) => c.type === GroupedContentDetail);
    const items = children?.filter(isItemComponent) ?? [];

    if (!detail) {
        throw new Error(
            'Grouped content component needs to have detail component.'
        );
    }

    return (
        <div className="c-grouped-content">
            <div className="c-grouped-content__detail">
                {detail}
                <div className="c-grouped-content__detail-nav">
                    {items.map((item, i) => (
                        <div
                            className={className(
                                'c-grouped-content__detail-nav-item',
                                {
                                    'c-grouped-content__detail-nav-item--active':
                                        i === activeItemIndex,
                                }
                            )}
                            onClick={() => setActiveItemIndex(i)}
                        >
                            {item.props.name}
                        </div>
                    ))}
                </div>
            </div>

            {items.map((item, i) => (
                <div
                    className={className('c-grouped-content__item', {
                        'c-grouped-content__item--hidden':
                            i !== activeItemIndex,
                    })}
                >
                    {item}
                </div>
            ))}
        </div>
    );
};

export default GroupedContent;
